






import { Component, Prop, Vue } from "vue-property-decorator";

interface StringMap {
  [key: string]: string;
}

@Component({})
export default class ExcelModel extends Vue {
  requestExcel() {
    this.request().then((response: StringMap) => {
      const fileURL = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = "excelExport.xlsx";
      link.click();
    });
    this.$emit("stop-loading");
  }

  @Prop(Function) request: any;
}

import http from "@/api/http";
class LotService {
  create(data: any, fields?: string) {
    return http.post("/lot", data, {
      params: {
        select: fields
      }
    });
  }
  getAll(storageId: number, filters?: any, fields?: string) {
    return http.get(`/lot/storage/${storageId}`, {
      params: {
        select: fields,
        ...filters
      }
    });
  }
  getMany(lotsId: string, fields?: string) {
    return http.get(`/lot/${lotsId}/multiple`, {
      params: {
        select: fields
      }
    });
  }
  getHistorial(storageId: number, filters?: any, fields?: string) {
    return http.get(`/lot/storage/${storageId}/historial`, {
      params: {
        select: fields,
        ...filters
      }
    });
  }
  get(lotId: number, fields?: string) {
    return http.get(`/lot/${lotId}`, {
      params: {
        select: fields
      }
    });
  }
  update(lotId: number, data: any, fields?: string) {
    return http.patch(`/lot/${lotId}`, data, {
      params: {
        select: fields
      }
    });
  }
  getPrices(storageId: number) {
    return http.get(`/lot/prices/${storageId}`);
  }
  getClients(storageId: number) {
    return http.get(`/lot/clients/${storageId}`);
  }
  getItemsReady(storageId: number, filters: string, fields?: string) {
    return http.get(`/lot/item_data/${storageId}?${filters}`, {
      params: {
        select: fields
      }
    });
  }
  delete(lotId: number) {
    return http.delete(`lot/${lotId}`);
  }
}

export default new LotService();

<template>
  <div class="card rounded-lg">
    <b-loading is-full-page v-model="loading" />
    <p class="text-2xl px-4 p-2 font-medium border-b-2">
      Agregar precio dolar
    </p>
    <div class="card-content">
      <div class="columns">
        <div class="column is-7 overflow-scroll h-64">
          <div
            class="card mb-3 p-2 border-2 shadow-none"
            v-for="item in items"
            :key="item.id"
          >
            <p class="text-lg">
              <b>#{{ item.id }}</b> {{ item.name }} {{ item.last_name }}
            </p>
            <div class="columns">
              <div class="column">
                <b>Peso:</b>
                {{ item.weight | number(2, 2) }} Kg
              </div>
              <div class="column">
                <b>Calidad:</b>
                {{ (item.quality / 100) | percentage(2) }}
              </div>
              <div class="column">
                <b>Precio:</b> {{ item.price | currency }}
              </div>
            </div>
          </div>
        </div>
        <div class="column is-5">
          <p class="text-2xl font-bold">
            Precio del dolar:
          </p>
          <b-field class="mt-3">
            <b-input
              placeholder="Precio dolar..."
              size="is-large"
              icon="dollar-sign"
              type="number"
              v-model="exchangeRate"
              step="0.01"
              min="0"
            >
            </b-input>
          </b-field>
          <b-button expanded type="is-primary" @click="addPrice"
            >Asignar precio</b-button
          >
        </div>
      </div>
    </div>
    <div class="card-footer"></div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from "vue-property-decorator";
import PaymentService from "@/api/payment";

@Component()
export default class AddDollar extends Vue {
  @Prop(Array) items;

  exchangeRate = 0;
  loading = false;

  addPrice() {
    if (this.exchangeRate > 0) {
      PaymentService.addPrice({
        item_data_ids: this.items.map(x => x.id),
        exchange_rate: this.exchangeRate
      }).then(response => {
        this.$buefy.notification.open({
          message: "Se ha actualizado el precio del dolar para los items",
          type: "is-success"
        });
        this.$emit("close", {
          price: this.exchangeRate,
          items: response.data
        });
      });
    } else {
      this.$buefy.notification.open({
        message: "El precio debe ser mayor a 0",
        type: "is-warning"
      });
    }
  }

  mounted() {
    this.loading = true;
    PaymentService.getDollar().then(response => {
      response.json().then(data => {
        this.exchangeRate = data.rates.MXN.toFixed(2);
        this.loading = false;
      });
    });
  }
}
</script>

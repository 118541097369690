import http from "@/api/http";
class PaymentService {
  getAll(storageId: number, filters?: any) {
    return http.get(`/payment/storage/${storageId}?paid=false`, {
      params: {
        ...filters
      }
    });
  }
  getPaid(storageId: number) {
    return http.get(`/payment/storage/${storageId}?paid=true`);
  }
  addPrice(data: any) {
    return http.patch(`/payment/item_data`, data);
  }
  pay(data: any) {
    return http.patch(`/payment/item_data/pay`, data);
  }
  getDollar() {
    return fetch("https://api.exchangeratesapi.io/latest?symbols=MXN&base=USD");
  }
  report(storageId: number) {
    return http.get(`/treasury/report/${storageId}`);
  }
}

export default new PaymentService();
































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import PaymentService from "@/api/payment";
import { mapGetters } from "vuex";
import AddDollar from "@/components/payments/AddDollar.vue";
import AddPayment from "@/components/payments/AddPayment.vue";
import ExcelModal from "@/components/excel/ExcelModal.vue";
import TreasuryService from "@/api/treasury";
import LotService from "@/api/lots";

@Component({
  computed: {
    ...mapGetters("UserModule", {
      storage: "getStorage"
    })
  },
  components: {
    AddDollar,
    AddPayment,
    ExcelModal
  }
})
export default class PorPagar extends Vue {
  [x: string]: any;
  storage!: any;
  loading = false;
  isAddingDollar = false;
  isPaying = false;
  updateKey = 1;
  columns = [
    {
      field: "nombre",
      label: "Nombre",
      searchable: true
    },
    {
      field: "fecha",
      label: "Fecha de entrega",
      searchable: true
    },
    {
      field: "lote",
      label: "Número de lote"
    },
    {
      field: "cantidad",
      label: "Cantidad de kg"
    },
    {
      field: "totalPagar",
      label: "Total a Pagar"
    }
  ];
  data: any = [];

  get aPagar() {
    const items: any[] = [];
    this.data.forEach((load: any) => {
      load.lots.forEach((lot: any) => {
        items.push(...lot.items.filter((x: any) => x.selected));
      });
    });
    return items;
  }

  updateDollarClosed(data: any) {
    // const ids = this.aPagar.map(x => x.id);
    this.data.forEach((load: any) => {
      load.lots.forEach((lot: any) => {
        lot.items.forEach((x: any) => {
          const index = data.items.findIndex((p: any) => p.id === x.id);
          if (index >= 0) {
            x.exchange_rate = data.price;
            x.amount_to_pay = data.items[index].amount_to_pay;
          }
        });
      });
    });
    this.isAddingDollar = false;
    this.updateKey++;
  }

  updatePayments(payed: any) {
    this.data.forEach((load: any) => {
      load.lots.forEach((lot: any) => {
        lot.items.forEach((x: any) => {
          const index = payed.findIndex((p: any) => p.id === x.id);
          if (index >= 0) {
            x.amount_paid = payed[index].payed;
          }
        });
      });
    });
    this.isPaying = false;
    this.updateKey++;
  }

  addKgPrice(lotId: number) {
    this.$buefy.dialog.prompt({
      message: `¿Cual es el precio por kg del lote?`,
      inputAttrs: {
        type: "number",
        placeholder: "Precio kg...",
        maxlength: 3,
        step: 0.01,
        min: 0
      },
      trapFocus: true,
      onConfirm: value =>
        LotService.update(lotId, { price_kg: value }).then(res => res)
    });
  }

  addDollarPrice() {
    this.isAddingDollar = true;
  }

  openPayment() {
    this.isPaying = true;
  }

  getPayments() {
    this.loading = true;
    PaymentService.getPaid(this.storage.id).then(response => {
      this.data = response.data.loads;

      this.loading = false;
    });
  }

  excelRequest() {
    this.loading = true;
    return TreasuryService.treasuryReportExcel(this.storage.id, {});
  }

  @Watch("pagination.current_page")
  changePage() {
    this.getPayments();
  }

  mounted() {
    this.getPayments();
  }
}

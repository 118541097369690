<template>
  <div class="card rounded-lg">
    <p class="text-2xl px-4 p-2 font-medium border-b-2">
      Pagar nueces
    </p>
    <div class="card-content">
      <div class="columns">
        <div class="column is-8 overflow-scroll h-64">
          <div
            class="card mb-3 p-2 border-2 shadow-none"
            v-for="item in items"
            :key="item.id"
          >
            <p class="text-lg">
              <b>#{{ item.id }}</b> {{ item.name }} {{ item.last_name }}
            </p>
            <div class="columns">
              <div class="column">
                <b>Peso:</b>
                {{ item.weight | number(2, 2) }} Kg
              </div>
              <div class="column">
                <b>Calidad:</b>
                {{ (item.quality / 100) | percentage(2) }}
              </div>
              <div class="column">
                <b>Precio:</b> {{ item.price | currency }}
              </div>
            </div>
          </div>
        </div>
        <div class="column is-4">
          <p class="text-2xl font-bold">
            Cantidad a pagar
          </p>
          <div class="field mt-4">
            <b-radio v-model="payment" :native-value="true">
              Liquidar
            </b-radio>
          </div>
          <div class="field">
            <b-radio v-model="payment" :native-value="false" type="is-info">
              Cantidad especifica
            </b-radio>
          </div>
          <b-field class="mt-3" v-if="!payment">
            <b-input
              placeholder="$..."
              size="is-medium"
              icon="dollar-sign"
              type="number"
              v-model="quantity"
              step="0.01"
              min="0"
            >
            </b-input>
          </b-field>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <b-button expanded type="is-primary" @click="pay">
        Aplicar pagos
      </b-button>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from "vue-property-decorator";
import PaymentService from "@/api/payment";

@Component()
export default class AddPayment extends Vue {
  @Prop(Array) items;
  payment = true;
  quantity = 0;
  pay() {
    PaymentService.pay({
      item_data_ids: this.items.map(x => x.id),
      liquidate: this.payment,
      quantity: this.quantity
    })
      .then(response => {
        this.$buefy.notification.open({
          message: "Se han pagado las nueces",
          type: "is-success"
        });
        this.$emit("close", response.data);
      })
      .catch(err => {
        this.$buefy.notification.open({
          message:
            "Es necesario que las nueces a pagar tengan valor del dolar,\
             calidad, precio por punto y peso",
          type: "is-danger"
        });
      });
    // if (this.exchangeRate > 0) {
    //   PaymentService.addPrice({
    //     item_data_ids: this.items.map(x => x.id),
    //     exchange_rate: this.exchangeRate
    //   });
    //   this.$buefy.notification.open({
    //     message: "Se ha actualizado el precio del dolar para los items",
    //     type: "is-success"
    //   });
    //   this.$emit("close", this.exchangeRate);
    // } else {
    //   this.$buefy.notification.open({
    //     message: "El precio debe ser mayor a 0",
    //     type: "is-warning"
    //   });
    // }
  }
}
</script>

import http from "@/api/http";

class TreasuryService {
  treasuryReportExcel(storageId: number, filters: Record<string, unknown>) {
    return http.post(
      `/item/storage/${storageId}`,
      {
        data_structure: {
          Lote: ["lot.lot_id"],
          Productor: ["client.name", "client.last_name"],
          function_array: {
            array_key: "item_data",
            operation: {
              key: "sample",
              operation: "=",
              comperation: false
            },
            keys: {
              KG: ["weight"],
              Rendimiento: ["quality"],
              Estatus: ["liquidated"]
            }
          },
          Punto: ["price"],
          Formula_1: {
            name: "lo",
            type: "formula",
            formula: "E{0}*G{0}*22046"
          },
          Formula_2: {
            name: "l",
            type: "formula",
            formula: "J{0}*L{0}"
          },
          "TC CLIENTE": {
            type: "set",
            value: "0.0"
          },
          "TC REAL": {
            type: "set",
            value: "0.0"
          },
          "PU USD": {
            type: "set",
            value: "0.0"
          },
          "PU MXN": {
            type: "set",
            value: "0.0"
          },
          "Importe MXN": {
            type: "formula",
            formula: "M{0}*D{0}"
          },
          "Importe USD": {
            type: "formula",
            formula: "L{0}*D{0}"
          },
          "Ganancia TC": {
            type: "formula",
            formula: "(K{0}*O{0})-N{0}"
          }
        }
      },
      {
        responseType: "blob",
        params: {
          select:
            "id, price, stack_type_id, in_storage, client_id, item_data.sample, item_data.weight, item_data.stacks, item_data.humidity, item_data.quality, item_data.liquidated, item_data.entry_date, item_data.id, entry_date, storage_id, description",
          excel: true,
          ...filters
        }
      }
    );
  }
}

export default new TreasuryService();
